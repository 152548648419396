var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"system-table"},[_vm._m(0),_c('div',{staticClass:"system-content"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"content-row"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Спасибо за оплату! "),_c('br'),_vm._v(" История подписок, cчета, договоры и акты хранятся в разделе "),_c('router-link',{staticClass:"link-custom",attrs:{"to":'/profile/subscription',"tag":"a","active-class":"active"}},[_vm._v("«Мой профиль»")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-header"},[_c('div',{staticClass:"header-block"},[_c('p',[_vm._v("Сценарий")])]),_c('div',{staticClass:"header-block"},[_c('p',[_vm._v("Тема")])]),_c('div',{staticClass:"header-block"},[_c('p',[_vm._v("Содержание")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row"},[_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" За 7 дней до конца подписки ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка заканчивается через 7 дней ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row"},[_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" За 3 дня до конца подписки ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка заканчивается через 3 дня ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row"},[_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" За 1 день до конца подписки ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка заканчивается через 1 день ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row"},[_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка закончилась ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка закончилась ")])]),_c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Пожалуйста, продлите подписку, чтобы не пропускать новые тендеры ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка продлена ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-block"},[_c('p',[_vm._v(" Подписка продлена ")])])
}]

export { render, staticRenderFns }
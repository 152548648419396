<template>
    <div class="wrapper">
        <div class="system-table">
            <div class="system-header">
                <div class="header-block">
                    <p>Сценарий</p>
                </div>
                <div class="header-block">
                    <p>Тема</p>
                </div>
                <div class="header-block">
                    <p>Содержание</p>
                </div>
            </div>
            <div class="system-content">
                <div class="content-row">
                    <div class="content-block">
                        <p>
                            За 7 дней до конца подписки
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Подписка заканчивается через 7 дней
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры
                        </p>
                    </div>
                </div>
                <div class="content-row">
                    <div class="content-block">
                        <p>
                            За 3 дня до конца подписки
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Подписка заканчивается через 3 дня
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры
                        </p>
                    </div>
                </div>
                <div class="content-row">
                    <div class="content-block">
                        <p>
                            За 1 день до конца подписки
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Подписка заканчивается через 1 день
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Пожалуйста, продлите подписку, чтобы не пропустить новые тендеры
                        </p>
                    </div>
                </div>
                <div class="content-row">
                    <div class="content-block">
                        <p>
                            Подписка закончилась
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Подписка закончилась
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Пожалуйста, продлите подписку, чтобы не пропускать новые тендеры
                        </p>
                    </div>
                </div>
                <div class="content-row">
                    <div class="content-block">
                        <p>
                            Подписка продлена
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Подписка продлена
                        </p>
                    </div>
                    <div class="content-block">
                        <p>
                            Спасибо за оплату!
                            <br>
                            История подписок, cчета, договоры и акты хранятся в разделе
                            <router-link
                                :to="'/profile/subscription'"
                                tag="a"
                                class="link-custom"
                                active-class="active"
                            >«Мой профиль»</router-link> 
                        </p>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "HistoryNotifications",
    }
    
</script>

<style lang="less">

    .system-header, .content-row{
        display: flex;
        gap: 60px;
        padding: 12px 0;

        .header-block, .content-block{
            flex: 1;

            &:last-child{
                flex: 3;
            }
        }
    }

    .header-block{
        font-weight: 500;
        color: #7D909B;
    }
</style>